import { type Auth0Callback, type Auth0Error, type AuthOptions, WebAuth } from 'auth0-js';
import { type InjectionKey, type Ref, inject, provide, ref } from 'vue';
import { type Auth0ConfigParams } from '@/types';
import { useI18n } from 'vue-i18n-bridge';

interface EmailPasswordParams {
  email: string;
  password: string;
}

const dbConnection = 'InfluencerUser';
const configErrorMessage = 'Authentication config not present, unable to perform authenticaiton actions';

// Provide/Inject Keys. Useful for type safety on provide/inject, and consistent lookups
export const authClientKey = Symbol('authClient') as InjectionKey<WebAuth | null>;
export const authConfigKey = Symbol('authConfig') as InjectionKey<Auth0ConfigParams | null>;
export const authErrorKey = Symbol('authError') as InjectionKey<Ref<string>>;
export const hasAuthErrorKey = Symbol('hasAuthError') as InjectionKey<Ref<boolean>>;
export const authIsLoadingKey = Symbol('authIsLoading') as InjectionKey<Ref<boolean>>;

/**
 * Global Initializer. Follows a semi-singleton pattern, where values are initialized and provided
 * when a default factory is used on injection. This should ensure that only 1 instance of these
 * globals should exist in an given vue app.
 */
export const initAuthentication = () => {
  const newUrlParams = window.location ? new URLSearchParams(window.location.search) : null;
  const sentFromParams = newUrlParams?.get('sentFrom') || false;

  const hasAuthError = inject(
    hasAuthErrorKey,
    () => {
      const hasAuthError = ref(false);
      provide(hasAuthErrorKey, hasAuthError);
      return hasAuthError;
    },
    true,
  );

  const authError = inject(
    authErrorKey,
    () => {
      const authError = ref('');
      provide(authErrorKey, authError);
      return authError;
    },
    true,
  );

  const authIsLoading = inject(
    authIsLoadingKey,
    () => {
      const authIsLoading = ref(false);
      provide(authIsLoadingKey, authIsLoading);
      return authIsLoading;
    },
    true,
  );

  const authConfig = inject(
    authConfigKey,
    () => {
      const config = window.configParams;
      if (config === undefined) {
        return null;
      }

      provide(authConfigKey, config);
      return config;
    },
    true,
  );

  const authClient = inject(
    authClientKey,
    () => {
      let params;

      if (location.hostname === 'localhost' || location.hostname === '127.0.0.1') {
        const urlParams = newUrlParams;

        params = {
          domain: process.env.VUE_APP_AUTH_DOMAIN as string,
          clientID: urlParams?.get('client') ?? (process.env.VUE_APP_AUTH_CLIENT_ID as string),
          redirectUri: urlParams?.get('redirect_uri') ?? (process.env.VUE_APP_AUTH_REDIRECT_TO as string),
          responseType: 'code',
        };
        // otherwise, use the decoded the URI component
      } else {
        const auth0Domain = window.configParams?.auth0Domain;

        params = Object.assign(
          {
            domain: auth0Domain,
            clientID: window.configParams?.clientID,
            redirectUri: window.configParams?.callbackURL,
            responseType: 'code',
            overrides: {
              __tenant: window.configParams?.auth0Tenant,
              __token_issuer: window.configParams?.authorizationServer.issuer,
            },
          },
          window.configParams?.internalOptions,
        );
      }
      const authClient = new WebAuth(params as AuthOptions);

      // Maintains parameter
      if (sentFromParams) {
        newUrlParams?.set('sentFrom', sentFromParams);
      }
      provide(authClientKey, authClient);

      return authClient;
    },
    true,
  );

  return { authClient, authConfig, authError, authIsLoading, hasAuthError };
};

const useAuthentication = () => {
  const { authClient, authConfig, authError: globalAuthError, authIsLoading, hasAuthError } = initAuthentication();
  const authError = ref('');
  const { t } = useI18n();
  const newUrlParams = window.location ? new URLSearchParams(window.location.search) : null;
  const sentFromParams = newUrlParams?.get('sentFrom') || false;

  const resetAuthErrors = () => {
    hasAuthError.value = false;
    globalAuthError.value = '';
    authError.value = '';
  };

  if (authClient === null) {
    globalAuthError.value = configErrorMessage;
    authError.value = configErrorMessage;
    hasAuthError.value = true;
  }

  /**
   * Definition of error codes: https://auth0.com/docs/libraries/common-auth0-library-authentication-errors
   */
  const handleAuthError = (error: Auth0Error | null) => {
    resetAuthErrors();

    if (error === null) {
      return;
    }

    let msg = '';

    const errorCode = error.code ? error.code : error.original?.response.body.message;

    switch (errorCode) {
      // Unauthorized is used in both login and signup and experienced due to a wrong username and
      // password combo or something wrong with the custom database login

      // Login Errors
      case 'access_denied':
      case 'invalid_user_password':
      case 'unauthorized':
      case 'not-unauthorized':
      case 'invalid-credentials':
        msg = t('Sorry, but that email and password combination did not match our records.');
        break;
      case 'too_many_attempts':
        msg = t(
          "Your account has been blocked after multiple consecutive login failures. We've sent you an email with instructions on how to unblock your account.",
        );
        break;
      case 'consent':
      case 'user-forbidden':
        msg = `${t("Looks like you're trying to sign in with a shopper account. Start becoming a creator")} <a href="${
          process.env.VUE_APP_LEGACY_HOME_PAGE
        }/apply/creator" target="_blank"> ${t('here')} </a>.`;
        break;

      // Signup Errors
      case 'invalid_signup':
      case 'user_exists':
      case 'username_exists':
        msg = t("The email you've entered is already associated with an account.");
        break;
      case 'password_dictionary_error':
      case 'password_no_user_info_error':
      case 'password_strength_error':
      case 'invalid_password':
      case 'invalid-password':
        msg = t(
          'Your password did not meet all the requirements. Passwords need to be at least 8 characters and have one uppercase, one lowercase, and one special character.',
        );
        break;
      case 'invalid_email':
      case 'invalid-email':
        msg = t("The email you've entered does not appear to be a valid email.");
        break;
      case undefined:
      case 'internal-system-error':
      default:
        msg = `${t(
          'We have encountered an error on our end. If this error persists, please contact customer support by logging a ticket',
        )} <a href="https://help.rewardstyle.com/" target="_blank"> ${t('here')} </a>.`;
    }

    globalAuthError.value = msg;
    authError.value = msg;
    hasAuthError.value = true;
  };

  const promisifyAuth0 = (boundFunction: ((callback: Auth0Callback<unknown, Auth0Error>) => void) | undefined) => {
    hasAuthError.value = false;

    if (boundFunction === undefined) {
      return Promise.resolve();
    }

    // Guard against multiple concurrent login/signup requests
    if (authIsLoading.value === true) {
      return Promise.resolve();
    }

    authIsLoading.value = true;

    return new Promise<void | string>((resolve, reject) => {
      boundFunction((error) => {
        handleAuthError(error);
        authIsLoading.value = false;

        if (error === null) {
          resolve();
        } else {
          reject(error);
        }
      });
    });
  };

  const login = ({ email, password }: EmailPasswordParams) => {
    return promisifyAuth0(authClient?.login.bind(authClient, { password, realm: dbConnection, username: email }));
  };

  const signup = ({ email, password }: EmailPasswordParams) => {
    return promisifyAuth0(
      authClient?.redirect.signupAndLogin.bind(authClient?.redirect, { connection: dbConnection, email, password }),
    );
  };

  return {
    authClient,
    authConfig,
    authError,
    handleAuthError,
    authIsLoading,
    globalAuthError,
    hasAuthError,
    resetAuthErrors,
    newUrlParams,
    sentFromParams,
    login,
    signup,
  };
};
export default useAuthentication;
