<template>
  <div class="mt-3 form-animation" :class="{ 'new-styles': hasASLFeatureFlag }" data-testid="login-form-wrapper">
    <rs-form ref="loginForm" v-model="isValid" @submit.prevent id="login-form">
      <div class="error--text mb-4" v-show="hasAuthError">
        <span v-html="globalAuthError" data-testid="error-message"></span>
      </div>
      <rs-text-field
        :autofocus="!hasASLFeatureFlag"
        :class="{ 'mb-4': !hasASLFeatureFlag }"
        v-model="formData.email"
        :rules="emailRule"
        :label="labels.email"
        required
        data-test-id="login-email-input"
        :error="hasAuthError"
        :disabled="hasLoginHint"
        :outline="hasASLFeatureFlag"
        @keyup="handleFormFieldChange"
      ></rs-text-field>
      <rs-text-field
        v-model="formData.password"
        :rules="passwordRule"
        :label="labels.password"
        required
        data-test-id="login-password-input"
        @click:append="visible = !visible"
        :append-icon="visible ? 'visibility' : 'visibility_off'"
        :type="visible ? 'text' : 'password'"
        :class="{ 'mb-4': !hasASLFeatureFlag }"
        :error="hasAuthError"
        :outline="hasASLFeatureFlag"
        @keyup="handleFormFieldChange"
      ></rs-text-field>
      <template v-if="!hasASLFeatureFlag">
        <rs-btn
          large
          rounded
          inverted
          data-test-id="login-submit-btn"
          class="loginBtn"
          :class="{ 'mb-4': hideApply }"
          id="login-button"
          type="submit"
          :disabled="authIsLoading || !isValid"
          :loading="authIsLoading"
          @click="handleSubmit"
          >{{ labels.logIn }}
        </rs-btn>
        <rs-btn
          class="mt-2 mb-4 apply__btn"
          large
          @click="clickEventApplyNow"
          data-test-id="request-access-btn"
          :disabled="authIsLoading"
          v-if="!hideApply"
          >{{ labels.applyNow }}
        </rs-btn>
        <div class="mb-5">
          <a
            @click="clickEventForgotPassword"
            class="forgot-password"
            data-test-id="forgot-password-link"
            v-t="'Forgot Password?'"
          ></a>
        </div>
      </template>
      <template v-else>
        <div class="mb-5">
          <a
            @click="clickEventForgotPassword"
            class="forgot-password"
            data-test-id="forgot-password-link"
            v-t="'Forgot Password?'"
          ></a>
        </div>
        <button
          data-test-id="login-submit-btn"
          class="rs-button loginBtn mb-4"
          type="submit"
          :disabled="authIsLoading || !isValid"
          @click="handleSubmit"
        >
          {{ labels.continue }}
        </button>
      </template>
    </rs-form>
    <sso-buttons v-if="hasSSOFeatureFlag" />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, getCurrentInstance, reactive, ref } from 'vue';
import { useI18n } from 'vue-i18n-bridge';
import SsoButtons from '@/components/SsoButtons.vue';
import { RsFormComponent } from '@/types';
import { validation } from '@/utils/validation';
import { isFlagActive, FeatureFlags } from '@/utils/featureFlags';
import useSSOAuthentication from '@/composables/useSSOAuthentication';
import useAuthentication from '@/composables/useAuthentication';
import { type Auth0Error } from 'auth0-js';

export default defineComponent({
  name: 'LoginForm',
  components: { SsoButtons },
  props: {
    hideApply: {
      type: Boolean,
      default: false,
    },
    collabsLogin: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const vm = getCurrentInstance();
    const { $amplitude } = vm?.proxy as Vue;
    const { automaticSsoFromQuery } = useSSOAuthentication();
    const { login, authIsLoading, globalAuthError, hasAuthError, newUrlParams, handleAuthError } = useAuthentication();

    const { t } = useI18n();

    const visible = ref(false);
    const loginForm = ref<RsFormComponent>();
    const hasASLFeatureFlag = computed(() => isFlagActive(FeatureFlags.ASL));
    const hasSSOFeatureFlag = computed(() => isFlagActive(FeatureFlags.SSO));
    const isValid = ref(false);

    const hasLoginHint = Boolean(newUrlParams?.get('login_hint')) || false;
    const hasConsentError = newUrlParams?.get('error') && newUrlParams?.get('error') === 'consent not provided';

    if (hasConsentError) {
      handleAuthError({ code: 'consent' } as Auth0Error);
    }

    const formData = reactive({
      email: newUrlParams?.get('login_hint') || '',
      password: '',
    });

    const emailRule = computed(() => [
      (v: any) => !!v || t('Email is required'),
      (v: any) => /.+@.+/.test(v) || t('Email is invalid'),
    ]);

    const passwordRule = computed(() => [(v: any) => !!v || t('Password is required')]);
    const forgotPasswordUrl = `${process.env.VUE_APP_SIGIL_URL}/forgot_password/?client_id=${
      props.collabsLogin ? 'UnVU3dMRvktGgVdWJWi05KpKrSVhj6smmfhanudB' : 'vur6eFeovCjKYU9Lkorx8TWLEdc0UHnZ2g60y01N'
    }`;

    const labels = computed(() => ({
      email: t('Enter your email'),
      password: t('Password') /* Password field label for login */,
      logIn: t('LOG IN'), // TODO: remove after ASL launch
      continue: t('Continue') /* Continue with email and password to login */,
      applyNow: t('APPLY NOW'),
    }));

    const handleFormFieldChange = () => {
      authIsLoading.value = false;
      hasAuthError.value = false;
    };

    // TODO: Can be removed with ASL launch
    const clickEventApplyNow = () => {
      const event = {
        event_type: '/login/apply-now',
        event_properties: { referrer: 'login' },
      };

      $amplitude?.track(event);
      window.location.href = `${process.env.VUE_APP_LEGACY_HOME_PAGE}/apply/creator`;
    };

    const clickEventForgotPassword = () => {
      const event = {
        event_type: '/auth/login/forgotpassword',
        event_properties: { referrer: 'login' },
      };

      $amplitude?.track(event);
      window.location.href = forgotPasswordUrl;
    };

    const handleSubmit = () => {
      if (!validation(loginForm.value)) {
        return;
      }
      hasAuthError.value = false;

      submitLogin();
    };

    const submitLogin = async () => {
      $amplitude?.track('/login-attempt', {
        email: formData.email,
      });

      try {
        await login(formData);
      } catch (e: unknown) {
        const errorEventProperties = Object.entries(e as Auth0Error).reduce((error, entries) => {
          const [key, value] = entries;
          error = {
            ...error,
            [`auth0-error:${key}`]: value,
          };
          return error;
        }, {});

        // TODO: will look to move amplitude into composable in ampliltude ticket
        $amplitude?.track('/auth/login/fail', errorEventProperties);
      }
    };

    // Triggers SSO from Query
    if (hasSSOFeatureFlag.value) {
      automaticSsoFromQuery();
    }

    return {
      formData,
      loginForm,
      isValid,
      handleSubmit,
      labels,
      visible,
      emailRule,
      passwordRule,
      forgotPasswordUrl,
      clickEventApplyNow,
      clickEventForgotPassword,
      handleFormFieldChange,
      hasLoginHint,
      hasASLFeatureFlag,
      hasSSOFeatureFlag,
      authIsLoading,
      globalAuthError,
      hasAuthError,
    };
  },
});
</script>

<style lang="scss" scoped>
#login-form {
  #login-button {
    background-color: #000;
    border: none !important;
    color: #fff !important;
    &:disabled {
      color: var(--grey-40) !important;
    }
  }
  .apply__btn {
    &:focus {
      background-color: #111 !important;
      color: #fff;
      border: none !important;
    }
  }

  .forgot-password {
    font-size: 1.5rem;
    mix-blend-mode: normal;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0.08em;
    line-height: 24px;
    margin: 1rem auto auto;
    text-align: center;
    display: block;
  }
}
.new-styles #login-form {
  .forgot-password {
    text-transform: unset;
    mix-blend-mode: unset;
    text-align: center;
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    letter-spacing: unset;
    display: block;
  }
  #login-button {
    margin-top: 0;
    border-radius: 4px !important;
  }
  .apply__btn {
    border: 2px solid var(--grey-50) !important;
    border-radius: 4px !important;
    &:hover,
    &:focus {
      border-color: var(--ltk-black) !important;
    }
  }
}

.form-animation {
  animation: form-slide 0.8s ease forwards;
}

@keyframes form-slide {
  from {
    transform: translateY(50px);
    opacity: 0;
  }

  to {
    transform: translateY(0px);
    opacity: 1;
  }
}
</style>

<style lang="scss">
.v-btn {
  .v-btn__content {
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 22px;
    letter-spacing: 0.08em;
    text-transform: uppercase;

    &:focus {
      border: none;
    }
  }

  &:disabled {
    background: var(--grey-10);
    color: var(--grey-40);
  }
}

.v-label {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: -0.005em;
  margin-bottom: 58px;
  color: #757575 !important;
}

.v-label--active {
  top: -5px;
}

.new-styles {
  .v-label {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: -0.005em;
    margin-bottom: 58px;
    color: var(--grey-50) !important;
  }

  .v-label--active {
    top: -5px;
  }

  .theme--light.v-text-field--outline:not(.error--text) > .v-input__control > .v-input__slot {
    border-color: var(--grey-50) !important;
  }
  .v-text-field--box .v-label--active,
  .v-text-field--full-width .v-label--active,
  .v-text-field--outline .v-label--active {
    transform: translateY(-27px) scale(0.85) !important;
    background-color: white !important;
    padding: 0 4px;
  }
}
</style>
